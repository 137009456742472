import React, { useEffect } from 'react';
import { ContactCard } from './ContactCard';
import { useAccountOverview } from '../../../../../features/account/account-overview/account-overview.hook';
import { ProfileDrawer } from './ProfileDrawer';
import { AccountsContact } from '../../../../../API/types';

import Styles from './style';
const { Container, ContactGroup, EmptyState } = Styles;

export const Stakeholders: React.FC = () => {
  const [selectedStakeholder, setSelectedStakeholder] = React.useState<AccountsContact | null>(
    null,
  );

  const { selectedAccount } = useAccountOverview();
  const sortedContacts = [...selectedAccount.contacts];
  sortedContacts.sort((a, b) => a.email.localeCompare(b.email));

  useEffect(() => {
    if (sortedContacts.length > 0) {
      setSelectedStakeholder(sortedContacts[0]);
    }
  }, [selectedAccount.id]);

  return (
    <>
      {sortedContacts.length === 0 && <EmptyState>No stakeholders found</EmptyState>}
      {sortedContacts.length > 0 && (
        <Container>
          <ContactGroup>
            {sortedContacts.map(contact => {
              const { email, id, name, noShows, talkRatio, totalMeetings, lastMeetingDate } =
                contact;
              return (
                <ContactCard
                  selected={selectedStakeholder?.id === contact.id}
                  onClick={() => {
                    setSelectedStakeholder(contact);
                  }}
                  attendees={totalMeetings - noShows}
                  lastMeeting={lastMeetingDate}
                  noShows={noShows}
                  name={name}
                  meetings={totalMeetings}
                  percentage={talkRatio}
                  email={email}
                  key={id}
                />
              );
            })}
          </ContactGroup>
          <ProfileDrawer stakeholder={selectedStakeholder} />
        </Container>
      )}
    </>
  );
};
