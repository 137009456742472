import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import Card from '../../../../../componentsV2/Card';
import { TopicDetailModal } from '../../../../portfolio-insights/components/TopicDetailModal';
import { DashboardFilterPovider } from '../../../../../features/shared/dashboard-filters/dashboard-filters.context';
import { addDays } from 'date-fns';
import { useAccountOverview } from '../../../../../features/account/account-overview/account-overview.hook';
import { useQuery } from '@tanstack/react-query';
import { useDashboardFilters } from '../../../../../features/shared/dashboard-filters/dashboard-filters.hooks';
import { coreService } from '../../../../../services/core/core-service';
import { DateRangeFilterStore } from '../../../../../features/shared/dashboard-filters/types';
import { Table } from '../../../../../componentsV2/Table';
import { useFilterValues } from '../../../../../features/insights-engine/filter-values/use-filter-values';
import { ViewAllTopicsModal } from '../../../../../components/insights-engine/ViewAllTopics';
import { Button, Select, Space } from 'antd';
import { TopicLabel } from '../../../../portfolio-insights/components/TopicLabel';

function markAllSelected<T extends object & { selected: boolean }>(items: T[]) {
  return items.map(it => {
    const itemsCopy = { ...it };
    itemsCopy.selected = true;
    return itemsCopy;
  });
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 300px);
  overflow-y: auto;
  gap: 8px;
`;

interface TopTopicsProps {
  accountId: string;
  insightType?: string;
  onTopicSelected: (topic: { id: string; name: string }) => void;
}

const TopTopics: React.FC<TopTopicsProps> = ({ accountId, onTopicSelected, insightType }) => {
  const { filters } = useDashboardFilters();
  const [sortBy, setSortBy] = useState<string>('mentions');
  const [showAllTopics, setShowAllTopics] = useState(false);

  const dateRange = useMemo(
    () => (filters['dateRange'] as DateRangeFilterStore)?.value?.interval,
    [filters.dateRange],
  );

  const { data, isFetching } = useQuery({
    queryKey: ['account-page-top-topics', dateRange[0], dateRange[1], insightType, sortBy],
    queryFn: async args => {
      const data = await coreService.getTopTopics(
        dateRange[0]!.toISOString(),
        dateRange[1]!.toISOString(),
        1,
        10,
        sortBy,
        { accountIds: [accountId], insightType },
      );
      return data;
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <ViewAllTopicsModal
        open={showAllTopics}
        onClose={() => setShowAllTopics(false)}
        onTopicClicked={topic => {
          setShowAllTopics(false);
          onTopicSelected(topic);
        }}
        filters={{
          from: dateRange[0]!.toISOString(),
          to: dateRange[1]!.toISOString(),
          accountIds: [accountId],
          insightType,
        }}
        columns={{
          accounts: false,
          mentions: true,
          meetings: true,
          arr: false,
        }}
      />
      <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <Space>
          <Button onClick={() => setShowAllTopics(true)}>View all topics</Button>
          <Select
            value={sortBy}
            options={[
              {
                value: 'mentions',
                label: '# of Mentions',
              },
              {
                value: 'meetings',
                label: '# of Meetings',
              },
            ]}
            onChange={value => setSortBy(value)}
          />
        </Space>
        <Table
          data={data?.topics || []}
          loading={isFetching}
          columns={[
            {
              key: 'topic',
              title: 'Topics',
              render: value => {
                return (
                  <TopicLabel
                    name={value.name}
                    onClick={() => onTopicSelected({ id: value.id, name: value.name })}
                  />
                );
              },
            },
            {
              key: 'mentions',
              title: '# of Mentions',
              dataIndex: 'mentions',
            },
            {
              key: 'meetings',
              title: '# of Meetings',
              dataIndex: 'meetings',
            },
          ]}
        />
      </div>
    </>
  );
};

export const Topics: React.FC = () => {
  const [selectedTopic, setSelectedTopic] = useState<{ id: string; name: string } | null>(null);
  const { filters } = useFilterValues();
  const { selectedAccount } = useAccountOverview();

  const dateIntervals = useMemo<[Date, Date]>(() => {
    return [addDays(new Date(), -120), new Date()];
  }, []);

  return (
    <Container>
      <DashboardFilterPovider
        filters={{
          dateRange: {
            type: 'dateRange',
            config: {
              value: 'custom',
              interval: dateIntervals,
            },
          },
          account: {
            type: 'account',
            config: {
              selected: [
                {
                  id: selectedAccount.mappingId,
                  name: selectedAccount.name,
                  provider: selectedAccount.provider,
                },
              ],
            },
          },
          geography: {
            type: 'list',
            componentProps: {
              label: 'Geography',
            },
            config: {
              allowNoValueSet: true,
              options: markAllSelected(filters?.geography || []),
            },
          },
          industry: {
            type: 'list',
            componentProps: {
              label: 'Industry',
            },
            config: {
              allowNoValueSet: true,
              options: markAllSelected(filters?.industry || []),
            },
          },
          revenueSegment: {
            type: 'list',
            componentProps: {
              label: 'Revenue Segment',
            },
            config: {
              allowNoValueSet: true,
              options: markAllSelected(filters?.revenueSegment || []),
            },
          },
          dealStage: {
            type: 'list',
            componentProps: {
              label: 'Deal Stage',
            },
            config: {
              allowNoValueSet: true,
              options: markAllSelected(filters?.dealStage || []),
            },
          },
          lifecycleStage: {
            type: 'list',
            componentProps: {
              label: 'Lifecycle Stage',
            },
            config: {
              allowNoValueSet: true,
              options: markAllSelected(filters?.lifecycleStage || []),
            },
          },
          arr: {
            type: 'numberRange',
            componentProps: {
              label: 'ARR',
            },
            config: {
              interval: [0, 0],
            },
          },
          daysUntilRenewal: {
            type: 'select',
            config: {
              defaultValue: -1,
              options: [
                {
                  value: -1,
                  label: 'All Days Until Renewal',
                },
                {
                  value: 30,
                  label: '30 Days Until Renewal',
                },
                {
                  value: 60,
                  label: '60 Days Until Renewal',
                },
                {
                  value: 90,
                  label: '90 Days Until Renewal',
                },
                {
                  value: 120,
                  label: '120 Days Until Renewal',
                },
              ],
            },
            componentProps: {
              label: 'Days Until Renewal',
              style: { width: 200 },
            },
          },
          insightType: {
            type: 'select',
            config: {
              defaultValue: 'Risks',
              options: [
                {
                  value: 'Risks',
                  label: 'Risks',
                },
                {
                  value: 'Product Feedback',
                  label: 'Product Feedback',
                },
                {
                  value: 'Advocacy',
                  label: 'Advocacy',
                },
                {
                  value: 'Growth Opportunities',
                  label: 'Growth Opportunities',
                },
              ],
            },
            componentProps: {
              label: 'Insight Type',
              style: { width: 200 },
            },
          },
        }}
      >
        <TopicDetailModal
          open={!!selectedTopic}
          onClose={() => setSelectedTopic(null)}
          topic={{
            id: selectedTopic?.id || '',
            name: selectedTopic?.name || '',
          }}
          currentFilters={null}
        />
        <Card title="Top Topics">
          <TopTopics onTopicSelected={setSelectedTopic} accountId={selectedAccount.mappingId} />
        </Card>
        <Card title="Top Risks">
          <TopTopics
            insightType="Risks"
            onTopicSelected={setSelectedTopic}
            accountId={selectedAccount.mappingId}
          />
        </Card>
        <Card title="Top Product Feedback">
          <TopTopics
            insightType="Product Feedback"
            onTopicSelected={setSelectedTopic}
            accountId={selectedAccount.mappingId}
          />
        </Card>
        <Card title="Top Advocacy">
          <TopTopics
            insightType="Advocacy"
            onTopicSelected={setSelectedTopic}
            accountId={selectedAccount.mappingId}
          />
        </Card>
        <Card title="Top Growth Opportunities">
          <TopTopics
            insightType="Growth Opportunities"
            onTopicSelected={setSelectedTopic}
            accountId={selectedAccount.mappingId}
          />
        </Card>
      </DashboardFilterPovider>
    </Container>
  );
};
