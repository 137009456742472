import React, { useEffect, useMemo, useState } from 'react';
import { Property } from '../Property';
import { coreService } from '../../../../../../services/core/core-service';
import { Select } from 'antd';
import { PropertyValue } from '../PropertyValue';
import { useToast } from '../../../../../../hooks/useToast';

type Deal = {
  id: string;
  name: string;
};

interface Props {
  primaryDealId: string | null;
  deals: Deal[];
  accountId: string;
  isEditing: boolean;
  onPrimaryDealChanged: (dealId: string) => unknown;
  handleIsEditing: (isEditing: boolean) => void;
}

export const DealNameProperty: React.FC<Props> = ({
  deals,
  primaryDealId,
  accountId,
  isEditing,
  handleIsEditing,
  onPrimaryDealChanged,
}) => {
  const [selectedPrimaryDeal, setSelectedPrimaryDeal] = useState<string | null>(primaryDealId);
  const { error, success } = useToast();

  useEffect(() => {
    setSelectedPrimaryDeal(primaryDealId);
  }, [primaryDealId]);

  const handleSave = async () => {
    try {
      if (selectedPrimaryDeal) {
        await coreService.changePrimaryDeal(accountId, selectedPrimaryDeal);
        onPrimaryDealChanged(selectedPrimaryDeal);
      }
      success('Primary deal changed successfully.');
    } catch (e) {
      console.error(e);
      error('Failed to change primary deal. Please try again.');
    } finally {
      handleIsEditing(false);
    }
  };

  const actions = isEditing
    ? [
        {
          label: 'Save',
          onClick: handleSave,
        },
        {
          label: 'Cancel',
          onClick: () => handleIsEditing(false),
        },
      ]
    : [];

  const primaryDealData = useMemo(() => {
    if (!Array.isArray(deals)) {
      return null;
    }
    return deals.find(deal => deal.id === selectedPrimaryDeal);
  }, [selectedPrimaryDeal, deals]);

  return (
    <Property name="" actions={actions}>
      <>
        {isEditing ? (
          <Select
            dropdownStyle={{ width: '200px' }}
            onChange={value => setSelectedPrimaryDeal(value)}
            value={primaryDealId}
            options={deals.map(deal => ({ label: deal.name, value: deal.id }))}
            size="small"
            style={{
              maxHeight: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          />
        ) : (
          <PropertyValue crmPropertyValue={primaryDealData?.name || 'No deal selected'} />
        )}
      </>
    </Property>
  );
};
