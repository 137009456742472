import React from 'react';
import Styles from './styles';
import { getInsightTypeConfiguration } from '../../../insights/insight-type-configuration/insight-type-configuration.service';

const { Container, Label } = Styles;

interface Props {
  type: string;
  styles?: React.CSSProperties;
}

// const typeToConfigKey: { [k: string]: string } = {
//   'TL;DR': 'Summary Items',
//   'Action Item': 'Action Items',
//   'Important Number': 'Important Numbers',
//   Advocacy: 'Advocacy',
//   Risk: 'Risks',
// };

export const SmartClipTypeIndicator: React.FC<Props> = ({ type, styles }) => {
  const config = getInsightTypeConfiguration(type);
  const TypeIcon = config.icon;

  return (
    <Container style={{ ...styles }}>
      <TypeIcon style={{ ...styles }} />
      <Label style={{ color: config.color, ...styles }}>{type}</Label>
    </Container>
  );
};
