import React, { useEffect, useState } from 'react';
import { TitledVerticalFlexLayout } from '../../features/shared/components/VerticalFixedLayout/VerticalFixedLayout';
import { Flex, Spin } from 'antd';
import { LeftTabs } from './components/LeftTabs';
import { AccountSelector } from './components/AccountSelector';
import { useAccountSearch } from '../../features/account/account-search/account-search.hook';
import { useAccountOverview } from '../../features/account/account-overview/account-overview.hook';
import { PropertyMappingProvider } from '../../features/crm/property-mapping/components/PropertyMappingProvider';
import { useFavouriteAccount } from '../../features/account/hooks/useFavouriteAccount';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { UpgradeToPro } from '../../componentsV2/UpgradeToPro';
import { RightTabs } from './components/RightTabs';
import { Overview } from './components/Overview';
import useFeatures from '../../hooks/useFeatures';

import Styles from './style';
const { Content, LeftColumn, RightColumn, CenteredContent, Header, Body } = Styles;

export const AccountsPage: React.FC = () => {
  const { selectedAccountFetching, selectedAccount, setSelectedAccount, favouriteStatusChanged } =
    useAccountOverview();
  const navigate = useNavigate();
  const { accounts, isFetching: isFetchingAccounts } = useAccountSearch();
  const { search } = useLocation();
  const params = useParams();

  const [currentLeftTab, setCurrentLeftTab] = useState('past-meetings');

  const features = useFeatures();
  const availableFeature = features.accountInsights;

  const goToAccount = (accountId: string, tab?: string) => {
    const searchParams = new URLSearchParams();
    if (tab) {
      searchParams.append('tab', tab);
    }

    const queryString = `${
      Array.from(searchParams.keys()).length > 0 ? `?${searchParams.toString()}` : ''
    }`;
    navigate(`/accounts/${accountId}${queryString}`);
  };

  const handleAccountSelected = (accountId: string) => {
    goToAccount(accountId);
  };

  const { favouriteButton } = useFavouriteAccount(
    selectedAccount.id,
    selectedAccount.isFavourited,
    favouriteStatusChanged,
  );

  const isLoading = isFetchingAccounts || selectedAccountFetching;

  // const handleOpenInCrm = () => {
  //   window.open(selectedAccount.url, '_blank');
  // };

  // const handleViewTrend = (category: string) => {
  //   window.open(
  //     `/insights?type=${category}&accountId=${selectedAccount.mappingId},${selectedAccount.name},${selectedAccount.provider}`,
  //     '_blank',
  //   );
  // };

  const handleSelectedTab = (tabName: string) => {
    goToAccount(selectedAccount.id, tabName);
  };

  useEffect(() => {
    if (accounts.length && !params.accountId) {
      goToAccount(accounts[0].id);
    }
  }, [accounts, params]);

  useEffect(() => {
    if (params.accountId) {
      setSelectedAccount(params.accountId);
    }

    if (search) {
      const params = new URLSearchParams(search);
      const tab = params.get('tab');
      if (tab) {
        setCurrentLeftTab(tab);
      }
    }
  }, [accounts, params, search]);

  return (
    <TitledVerticalFlexLayout
      title={
        <Header>
          <>Account Insights</>
        </Header>
      }
    >
      <UpgradeToPro plan="startup" display={!availableFeature}>
        {isLoading ? (
          <CenteredContent>
            <Spin />
          </CenteredContent>
        ) : (
          <Body>
            <Flex align="flex-end" justify="space-between">
              <Flex gap={8}>
                <AccountSelector onSelect={handleAccountSelected} value={selectedAccount} />
                {favouriteButton}
              </Flex>

              <PropertyMappingProvider>
                <Overview />
              </PropertyMappingProvider>
            </Flex>

            <Content>
              <LeftColumn>
                <LeftTabs currentTab={currentLeftTab} onTabSelected={handleSelectedTab} />
              </LeftColumn>

              <RightColumn>
                <RightTabs />
              </RightColumn>
            </Content>
          </Body>
        )}
      </UpgradeToPro>
    </TitledVerticalFlexLayout>
  );
};
