import { useCallback, useContext, useMemo } from 'react';
import { recapManagementContext } from './context';
import {
  createInsight as createInsightService,
  editInsight as editInsightService,
  removeInsight as removeInsightService,
} from './service';
import { coreService } from '../../../services/core/core-service';
import { Insight, PublicVisibilityConfig } from './types';
import { v4 } from 'uuid';

export const useRecapManagement = () => {
  const { store } = useContext(recapManagementContext);
  const state = store();

  const changeTitle = useCallback(
    async (title: string) => {
      const previousTitle = state.recap.title;
      try {
        await coreService.updateEvent(state.recap.id, { title });
        state.titleChanged(title);
      } catch (error) {
        state.titleChanged(previousTitle);
        throw error;
      }
    },
    [state.recap.title, state.recap.id],
  );

  const changeType = useCallback(
    async (type: string) => {
      const previousType = state.recap.type;
      state.typeChanged(type);
      try {
        await coreService.updateMeetingType(state.recap.id, type);
      } catch (error) {
        state.typeChanged(previousType);
        throw error;
      }
    },
    [state.recap.type, state.recap.id],
  );

  const changeWorkspaceVisibility = useCallback(
    async (visibility: string) => {
      const previousVisibility = state.recap.workspaceVisibility;
      state.workspaceVisibilityChanged(visibility);
      try {
        await coreService.updateWorkspaceVisibility(state.recap.id, visibility);
      } catch (error) {
        state.workspaceVisibilityChanged(previousVisibility);
        throw error;
      }
    },
    [state.recap.workspaceVisibility, state.recap.id],
  );

  const changePublicVisibility = useCallback(
    async (visibility: Partial<PublicVisibilityConfig>) => {
      const previousVisibility = state.recap.publicVisibilityConfig;
      const newVisibility: PublicVisibilityConfig = Object.assign(
        {},
        previousVisibility,
        visibility,
      );
      state.publicVisibilityChanged(visibility);
      try {
        await coreService.updatePublicVisibility(state.recap.id, newVisibility);
      } catch (error) {
        state.publicVisibilityChanged(previousVisibility);
        throw error;
      }
    },
    [state.recap.publicVisibilityConfig, state.recap.id],
  );

  const createInsight = useCallback(
    async (category: string) => {
      const newInsight: Insight = {
        id: v4(),
        text: 'Add your own text',
        category,
        hidden: false,
        favourite: false,
        excludedFromInsightsEngine: true,
        chunks: [],
      };
      try {
        state.insightAdded(newInsight);
        const newInsightId = await createInsightService(state.recap.id, category);
        state.insightIdReplaced(newInsight.id, newInsightId);
      } catch (error) {
        state.insightRemoved(newInsight);
        throw error;
      }
    },
    [state.recap.id],
  );

  const editInsight = useCallback(
    async (insightId: string, category: string, text: string) => {
      try {
        state.insightTextModified(insightId, text);
        await editInsightService(state.recap.id, insightId, category, text);
      } catch (error) {
        throw error;
      }
    },
    [state.recap.id],
  );

  const removeInsight = useCallback(
    async (insightId: string, category: string) => {
      const insight = state.recap.insights.find(ins => ins.id === insightId);
      if (!insight) {
        return;
      }

      try {
        state.insightRemoved(insight);
        await removeInsightService(state.recap.id, insightId, category);
      } catch (error) {
        state.insightAdded(insight);
        throw error;
      }
    },
    [state.recap.id, state.recap.insights],
  );

  const privateLink = useMemo(() => {
    return window.location.href.split('?shareCode')[0];
  }, []);

  const requestReport = useCallback(
    async (reportId: string) => {
      try {
        await coreService.generateMeddicc(state.recap.id);
        state.reportProcessingStatusChanged(reportId, true);
      } catch (error) {
        state.reportProcessingStatusChanged(reportId, false);
        throw error;
      }
    },
    [state.recap.id],
  );

  const getMessageAtTimestamp = useCallback(
    (timestamp: number): { id: string; index: number } | null => {
      const messages = state.recap.transcript;
      let message: { id: string; index: number } | null = null;

      for (let i = 0; i < state.recap.transcript.length; i++) {
        const time = Number(messages[i].startTime);
        if (time <= timestamp) {
          message = { id: messages[i].id, index: i };
        } else {
          break;
        }
      }
      return message;
    },
    [state.recap.transcript],
  );

  const getMessageById = useCallback(
    (messageId: string) => {
      return state.recap.transcript.find(msg => msg.id === messageId);
    },
    [state.recap.transcript],
  );

  const includeInsightInEngine = useCallback(async (insightId: string) => {
    state.insightExclusionFromInsightsEngineChanged(insightId, false);
    await coreService.includeInsight(insightId);
  }, []);

  const excludeInsightFromEngine = useCallback(async (insightId: string) => {
    state.insightExclusionFromInsightsEngineChanged(insightId, true);
    await coreService.excludeInsight(insightId);
  }, []);

  const excludeRecapFromInsights = useCallback(() => {
    const previousValue = state.recap.excludedFromInsightsEngine;
    state.exclusionFromInsightsEngineChanged(true);
    coreService.excludeRecapFromInsights(state.recap.id).catch(e => {
      state.exclusionFromInsightsEngineChanged(previousValue);
      throw e;
    });
  }, [state.recap.id, state.recap.excludedFromInsightsEngine]);

  const includeRecapFromInsights = useCallback(() => {
    const previousValue = state.recap.excludedFromInsightsEngine;
    state.exclusionFromInsightsEngineChanged(false);
    coreService.includeRecapFromInsights(state.recap.id).catch(e => {
      state.exclusionFromInsightsEngineChanged(previousValue);
      throw e;
    });
  }, [state.recap.id, state.recap.excludedFromInsightsEngine]);

  return {
    excludedFromInsights: state.recap.excludedFromInsightsEngine,
    cropping: state.recap.cropping,
    account: state.recap.account,
    isDemo: state.recap.isDemo,
    getMessageAtTimestamp,
    getMessageById,
    isFetching: state.isFetching,
    playback: state.recap.playback,
    id: state.recap.id,
    fetchError: state.fetchError,
    host: state.recap.host,
    started: state.recap.started,
    ended: state.recap.ended,
    scheduledStartDate: state.recap.scheduledStartDate,
    scheduledEndDate: state.recap.scheduledEndDate,
    changePublicVisibility,
    status: state.recap.status,
    categories: state.recap.categories,
    insights: state.recap.insights,
    participantsType: state.recap.participantsType,
    speakerAnalytics: state.recap.speakerAnalytics,
    publicVisibility: state.recap.publicVisibilityConfig,
    participants: state.recap.participants,
    followUp: state.recap.followUp,
    transcript: state.recap.transcript,
    workspaceVisibility: state.recap.workspaceVisibility,
    changeWorkspaceVisibility,
    privateLink,
    isBasic: state.recap.isBasic,
    type: state.recap.type,
    changeType,
    title: state.recap.title,
    isPublic: state.recap.isPublic,
    changeTitle,
    createInsight,
    editInsight,
    removeInsight,
    reports: state.recap.reports,
    excludeRecapFromInsights,
    includeRecapFromInsights,
    includeInsightInEngine,
    excludeInsightFromEngine,
    permissions: state.recap.permissions,
    requestReport,
  };
};
