import React, { PropsWithChildren, ReactElement, ReactNode } from 'react';
import Styles from './styles';
import { PropertyValue } from '../PropertyValue';

interface Props {
  actions?: {
    label?: string;
    icon?: ReactElement;
    onClick: () => unknown;
  }[];
  name?: string | ReactNode;
  value?: string | null | ReactElement;
}

const { PropertyWrapper, PropertyName, ActionText } = Styles;

export const Property: React.FC<PropsWithChildren<Props>> = ({
  actions,
  name,
  value,
  children,
}) => {
  return (
    <PropertyWrapper>
      <div style={{ display: 'flex', gap: '5px', alignItems: 'left' }}>
        {name && <PropertyName>{name}</PropertyName>}
        {children || (value && <PropertyValue crmPropertyValue={value}>{value}</PropertyValue>)}
        {actions && actions?.length > 0 ? (
          actions?.map(a => <ActionText onClick={a.onClick}>{a.icon || a.label}</ActionText>)
        ) : (
          <></>
        )}
      </div>
    </PropertyWrapper>
  );
};
