import styled from 'styled-components';

const Text = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #0a1e2c;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

const NotMappedText = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-md, 8px);
  color: var(--Primary-500, #ff6c54);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
`;

const ActionText = styled.span`
  color: #ff6b54;
  font-family: 'Cera Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
  text-decoration-line: underline;
`;

const Section = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: auto auto auto auto;
  border-radius: var(--4px, 8px);
  border: 1px solid #d9d9d9;
  padding: 12px;
  grid-gap: 5px 20px;
  min-width: 150px;
`;

const SectionTitle2 = styled.div`
  position: absolute;
  color: #566976;
  text-align: right;
  font-family: 'Cera Pro Medium';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.06px;
  right: 20px;
  margin-top: -8px;
  background-color: #eff0f6;
  padding: 0 8px;
`;

const SectionHeader = styled.div`
  color: #566976;
  text-align: left;
  font-family: 'Cera Pro';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.06px;
`;

export default {
  Text,
  NotMappedText,
  ActionText,
  Section,
  SectionTitle2,
  SectionHeader,
};
