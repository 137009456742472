import React, { useMemo, useState } from 'react';
import Styles from './style';
import { Collapse } from 'antd';
import { MeetingRowPanelHeader } from './Header';
import { Insight } from '../../../../../../componentsV2/Insight';
import { mapMessagesList } from '../../../../../../API/utils';
import { Message as RecapMessage } from '../../../../../../redux/typings/recap';
import { AiItem } from '../../../../../../features/account/account-favourites/account-favourites-types';

const { StyledColapse, InsightSectionContianer, InsightSectionTitle } = Styles;
const { Panel } = Collapse;

type InsightType = {
  id: string;
  text: string;
  excludedFromInsightsEngine: boolean;
  messageChunks: {
    from: number;
    to: number;
    messageId: string;
  }[];
};

type Message = {
  id: string;
  content: string;
  startTime: number;
  endTime: number;
  duration: number;
  speaker: string;
};

interface Props {
  title: string;
  messages: Message[];
  actionItems: InsightType[];
  tlDr: InsightType[];
  description: string;
  participantsList: { email: string }[];
  host: string;
  meetingType: string;
  meetingId: string;
  id: string;
  canExclude?: boolean;
  onClick: () => unknown;
  onSelectedChange: (selected: boolean) => unknown;
}

export const MeetingRow: React.FC<Props> = ({
  description,
  host,
  meetingType,
  participantsList,
  title,
  id,
  meetingId,
  tlDr,
  messages,
  actionItems,
  canExclude = false,
  onClick,
  onSelectedChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const tlDrItems: { item: AiItem; messages: RecapMessage[] }[] = useMemo(() => {
    return tlDr.map(insight => {
      const messageIds = new Set<string>();
      insight.messageChunks.forEach(itc => messageIds.add(itc.messageId));

      const filteredMessages = messages.filter(msg => {
        return messageIds.has(msg.id);
      });

      return {
        item: {
          id: insight.id,
          text: insight.text,
          hidden: false,
          excludedFromInsightsEngine: insight.excludedFromInsightsEngine,
          chunks: insight.messageChunks.map(mc => ({ ...mc, id: 'unknown' })),
        },
        messages: filteredMessages.map(msg => {
          return {
            id: msg.id,
            content: msg.content,
            speaker: msg.speaker,
            actionItems: [],
            keyMoments: [],
            startedTime: String(msg.startTime),
            mapping: [],
            words: [],
            duration: String(msg.duration),
            eventId: meetingId,
            actionItem: false,
          };
        }),
      };
    });
  }, [tlDr]);

  const actionItemsMapped: { item: AiItem; messages: RecapMessage[] }[] = useMemo(() => {
    return actionItems.map(insight => {
      const messageIds = new Set<string>();
      insight.messageChunks.forEach(itc => messageIds.add(itc.messageId));

      const filteredMessages = messages.filter(msg => {
        return messageIds.has(msg.id);
      });

      return {
        item: {
          id: insight.id,
          text: insight.text,
          hidden: false,
          excludedFromInsightsEngine: insight.excludedFromInsightsEngine,
          chunks: insight.messageChunks.map(mc => ({ ...mc, id: 'unknown' })),
        },
        messages: filteredMessages.map(msg => {
          return {
            id: msg.id,
            content: msg.content,
            speaker: msg.speaker,
            actionItems: [],
            keyMoments: [],
            startedTime: String(msg.startTime),
            mapping: [],
            words: [],
            duration: String(msg.duration),
            eventId: meetingId,
            actionItem: false,
          };
        }),
      };
    });
  }, [tlDr]);

  return (
    <StyledColapse size="small" onChange={() => setIsOpen(!isOpen)} style={{ border: '0' }}>
      <Panel
        header={
          <MeetingRowPanelHeader
            onSelectedChange={selected => onSelectedChange(selected)}
            isSelected={isOpen}
            id={id}
            description={description}
            host={host}
            meetingType={meetingType}
            participantsList={participantsList}
            title={title.trim()}
          />
        }
        key={'key'}
        showArrow={false}
        style={{ flex: '1' }}
      >
        <div
          style={{ whiteSpace: 'pre-line', display: 'flex', gap: '12px', flexDirection: 'column' }}
        >
          <InsightSectionContianer>
            <InsightSectionTitle>TL;DR</InsightSectionTitle>
            {tlDrItems.length > 0 ? (
              tlDrItems.map(insight => {
                return (
                  <Insight
                    isRecapExcludedFromInsightsEngine={false}
                    canEdit={false}
                    canExclude={canExclude}
                    key={insight.item.id}
                    insight={insight.item}
                    text={insight.item.text.replace('• ', '')}
                    color={'#EFF6FF'}
                    isEditing={false}
                    isOpen={false}
                    played={false}
                    hoverColor={'#eff6ff80'}
                    speakers={undefined}
                    editable={false}
                    smartClipColor={'#EFF6FF'}
                    ready={true}
                    isPublic={true}
                    insightsCount={tlDr.length}
                    recapId={meetingId}
                    messages={mapMessagesList(insight.messages, [insight.item])}
                    onClick={() => {
                      return;
                    }}
                    manageInsight={() => {
                      return;
                    }}
                    itemPlayed={() => {
                      return;
                    }}
                    onStopEditing={() => {
                      return;
                    }}
                    onDelete={() => {
                      return;
                    }}
                    onEdit={() => {
                      return;
                    }}
                    play={() => {
                      return;
                    }}
                    removeInsight={() => {
                      return;
                    }}
                    addInsight={() => {
                      return;
                    }}
                  />
                );
              })
            ) : (
              <p>No TL;DR</p>
            )}
          </InsightSectionContianer>

          <InsightSectionContianer>
            <InsightSectionTitle>Action Items</InsightSectionTitle>
            {actionItemsMapped.length > 0 ? (
              actionItemsMapped.map(insight => {
                return (
                  <Insight
                    isRecapExcludedFromInsightsEngine={false}
                    key={insight.item.id}
                    insight={insight.item}
                    text={insight.item.text.replace('• ', '')}
                    color={'#EFF6FF'}
                    isEditing={false}
                    isOpen={false}
                    played={false}
                    hoverColor={'#eff6ff80'}
                    speakers={undefined}
                    editable={false}
                    smartClipColor={'#EFF6FF'}
                    ready={true}
                    isPublic={true}
                    insightsCount={tlDr.length}
                    recapId={meetingId}
                    messages={mapMessagesList(insight.messages, [insight.item])}
                    manageInsight={() => {
                      return;
                    }}
                    itemPlayed={() => {
                      return;
                    }}
                    onClick={() => {
                      return;
                    }}
                    onStopEditing={() => {
                      return;
                    }}
                    onDelete={() => {
                      return;
                    }}
                    onEdit={() => {
                      return;
                    }}
                    play={() => {
                      return;
                    }}
                    removeInsight={() => {
                      return;
                    }}
                    addInsight={() => {
                      return;
                    }}
                  />
                );
              })
            ) : (
              <p>No action items</p>
            )}
          </InsightSectionContianer>
        </div>
      </Panel>
    </StyledColapse>
  );
};
