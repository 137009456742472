import React from 'react';
import { MeddiccForm } from '../../../../../components/reports/MeddiccForm';
import { coreService } from '../../../../../services/core/core-service';
import { useAccountOverview } from '../../../../../features/account/account-overview/account-overview.hook';
import useFeatures from '../../../../../hooks/useFeatures';

export const AccountMeddicc: React.FC = () => {
  const { meddicc } = useFeatures();
  const { selectedAccount } = useAccountOverview();

  return (
    <div style={{ height: 'calc(100vh - 300px)', overflowY: 'auto', backgroundColor: 'white' }}>
      <MeddiccForm
        isFeatureAvailable={meddicc || false}
        hasPermissions
        fetchFunction={() => {
          return coreService.getAccountReport(selectedAccount.id, 'meddicc').then(data => {
            if (!data.report) {
              return {
                isProcessing: false,
                sections: [],
              };
            }

            return {
              isProcessing: data.report?.isProcessing,
              sections: data.report?.sections,
            };
          });
        }}
        updateFunction={section => {
          return coreService.updateAccountReport(selectedAccount.id, 'meddicc', section);
        }}
        processFunction={() => coreService.generateAccountReport(selectedAccount.id, 'meddicc')}
      />
    </div>
  );
};
