import React, { useEffect, useState } from 'react';
import { usePastMeetings } from '../../../meetings/past-meetings/past-meetings.hook';
import { DateLabel } from '../components/DateLabel';
import { isSameDay, startOfTomorrow } from 'date-fns';
import { MeetingCard } from '../components/MeetingCard';
import { Button, Dropdown, Spin } from 'antd';
import { OptionalTooltipWrapper } from '../../../../componentsV2/OptionalTooltipWrapper';
import { useToast } from '../../../../hooks/useToast';
import { coreAPI } from '../../../../API/core';
import { DropdownVisibility } from '../../../../componentsV2/DropdownVisibility';
import { AccountModal } from '../../../meetings/account-selection/components/AccountModal';
import { DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import { MeetingTypeSelector } from '../UpcomingsTab/components/MeetingTypeSelector';
import PastMeetingsFilterBar from '../../components/PastMeetingsFilterBar';
import InfiniteScroll from 'react-infinite-scroll-component';
import RecapStatus from './components/RecapStatus';

import Styles from './styles';
const { Container, CenteredContent } = Styles;

export const RecapsTab: React.FC = () => {
  const {
    hasNextPage,
    events,
    isFetching,
    keepFetching,
    deleteEvent,
    updateMeetingAccount,
    updateAccountsFiltered,
    onInsightChanged,
  } = usePastMeetings();
  const { success, error } = useToast();

  const [height, setHeight] = useState(800);
  const [loading, setLoading] = useState('');
  // Not using setCurrentDate to avoid unnecesary re-renders. It's only to calculate dates.
  let [currentDate] = useState(startOfTomorrow());

  const onDeleteRecap = (id: string) => {
    setLoading(id);
    const coreClient = new coreAPI();
    coreClient
      .removeRecap(id)
      .then(() => {
        deleteEvent(id);
        success('Recap removed successfully');
      })
      .catch((errorMessage: { message: any }) => {
        error(`Error removing recap: ${errorMessage.message}.`);
      })
      .finally(() => {
        setLoading('false');
      });
  };

  useEffect(() => {
    setHeight(window.outerHeight - 300);
  }, [window.screen.height, window.screen.width]);

  return (
    <Container>
      <PastMeetingsFilterBar />

      {isFetching && events.length === 0 ? (
        <CenteredContent>
          <Spin />
        </CenteredContent>
      ) : (
        <InfiniteScroll
          dataLength={events.length}
          next={() => keepFetching()}
          height={height}
          hasMore={hasNextPage}
          loader={
            <div
              style={{ display: 'flex', width: '100%', justifyContent: 'center', height: '40px' }}
            >
              <Spin />
            </div>
          }
          scrollThreshold={0.6}
        >
          {events.map(event => {
            const comps = [];
            const date = event.started || event.scheduledStartDate;
            if (!isSameDay(new Date(date), currentDate)) {
              currentDate = new Date(date);
              comps.push(<DateLabel date={date} key={'date-label' + currentDate.toString()} />);
            }

            comps.push(
              <MeetingCard
                event={event}
                isReady={event.status === 'READY'}
                secondaryActions={[
                  event.visibility ? (
                    <>
                      <DropdownVisibility
                        hasPermissions={event.permissions.canManageWorkspaceVisibility}
                        visibility={event.visibility}
                        id={event.id}
                      />
                      <MeetingTypeSelector
                        meetingType={event.type}
                        eventId={event.id}
                        hasPermissions={event.permissions.canChangeMeetingTypes}
                      />
                    </>
                  ) : (
                    <></>
                  ),
                ]}
                tertiaryActions={[
                  <RecapStatus status={event.status!} subStatus={event.subStatus || ''} />,
                  <Dropdown
                    menu={{
                      items: [
                        {
                          label: (
                            <OptionalTooltipWrapper
                              value="Only the Owner can remove this Recap"
                              display={!event.permissions.canDeleteRecap}
                            >
                              <span style={{ color: 'red' }}>Remove Recap</span>
                            </OptionalTooltipWrapper>
                          ),
                          disabled: !event.permissions.canDeleteRecap,
                          key: 'remove',
                          icon: <DeleteOutlined style={{ color: 'red' }} />,
                          onClick: () => {
                            if (event.permissions.canDeleteRecap) onDeleteRecap(event.id);
                          },
                        },
                      ],
                    }}
                  >
                    <Button
                      type="default"
                      icon={<MoreOutlined />}
                      size="small"
                      loading={loading === event.id}
                      disabled={loading === event.id}
                    />
                  </Dropdown>,
                ]}
                onAccountRemoved={id => updateMeetingAccount(id, null)}
                onAccountClicked={updateAccountsFiltered}
                onInsightToggleChanged={v => onInsightChanged(event.id, v)}
              />,
            );
            return <>{comps}</>;
          })}
        </InfiniteScroll>
      )}
      <AccountModal onAccountChanged={updateMeetingAccount} />
    </Container>
  );
};
