import Styles from './styles';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ActionsContainer, BulletEditable, Header } from './styles';
import { removeBullet } from './utils';
import {
  BarChartOutlined,
  CaretDownOutlined,
  CaretRightOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  StarOutlined,
} from '@ant-design/icons';
import { Button, Checkbox, Tooltip } from 'antd';
import { Message } from './components/Message';
import { Menu, MenuItem, MenuLabel } from '../Menu';
import { GenerativeAIItem } from '../../redux/typings/recap';
import { OptionalTooltipWrapper } from '../OptionalTooltipWrapper';
import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/selectors';
import { SubscribeToProPlanLink, UpgradeToProBadge } from '../UpgradeToPro';
import { UtteranceSegment } from '../../features/recapv2/recap-management/types';
import { useSearchParams } from 'react-router-dom';
import { useRecapManagement } from '../../features/recapv2/recap-management/hook';
import { extractError } from '../../utils/api';
import { useToast } from '../../hooks/useToast';
import { InsightExcluded } from '../../features/shared/components/Icons/InsightExcluded';
import { InsightIncluded } from '../../features/shared/components/Icons/InsightIncluded';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { useRecapAnalytics } from '../../features/recapv2/recap-analytics/hooks/useRecapAnalytics';

const { FloatingBar, Space, MessageList, Container } = Styles;
interface Props {
  insight: GenerativeAIItem;
  isRecapExcludedFromInsightsEngine: boolean;
  text: string;
  color: string;
  isEditing: boolean;
  isOpen: boolean;
  played: boolean;
  hoverColor: string;
  speakers?: string[];
  editable?: boolean;
  smartClipColor: string;
  ready: boolean;
  isPublic: boolean;
  insightsCount: number;
  recapId?: string;
  messages: {
    id: string;
    content: string;
    mapping: UtteranceSegment[];
    startedTime: string;
    speaker: string;
  }[];
  canExclude?: boolean;
  canEdit?: boolean;
  openInsight?: boolean;
  openInsightSamePage?: boolean;
  manageInsight: () => unknown;
  itemPlayed: () => unknown;
  onClick: () => unknown;
  onStopEditing: () => unknown;
  onDelete: () => unknown;
  onEdit?: (text: string) => unknown;
  play: (time: number) => void;
  removeInsight: (id: string) => void;
  addInsight: (args: { id: string; text: string; eventId: string }) => unknown;
}

export const Insight: React.FC<Props> = ({
  insight,
  text,
  isEditing,
  isOpen,
  color,
  hoverColor,
  smartClipColor,
  played,
  isPublic,
  insightsCount,
  messages,
  recapId,
  isRecapExcludedFromInsightsEngine,
  canEdit = true,
  openInsight = false,
  canExclude = false,
  editable = false,
  openInsightSamePage = false,
  // ready,
  // itemPlayed,
  manageInsight,
  onStopEditing,
  onDelete,
  onClick,
  onEdit,
  play,
  removeInsight,
  addInsight,
}) => {
  const [bulletText, setBulletText] = useState('');
  const [editingText, setEditingText] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [expanded, setIsExpanded] = useState(false);
  const [scrollRef, setScrollRef] = useState<HTMLDivElement | null>(null);
  const { excludeInsightFromEngine, includeInsightInEngine, permissions } = useRecapManagement();
  const [isContainerHovered, setIsContainerHovered] = useState(false);
  const { error: errorToast, success: successToast } = useToast();
  const { insightExclusionToggled } = useRecapAnalytics();

  const user = useSelector(selectUser);

  const ref = useRef<HTMLDivElement>(null);

  const showFloatingBar = isEditing || isContainerHovered;
  const showDelete = !isPublic && editable;
  const showEdit = !isPublic && editable;
  const showStarInsight = !isPublic;
  const hasChunks = insight.chunks.length > 0;
  const disableCheckbox = (insightsCount >= 7 && !isChecked) || !hasChunks;
  const canFavorite = user?.features.favoritesFolder;

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const textBulletRemoved = removeBullet(text);
    setBulletText(textBulletRemoved);
    setEditingText(textBulletRemoved);
    if (ref.current) {
      ref.current.innerHTML = text;
    }
  }, [text]);

  useEffect(() => {
    if (searchParams.get('insightId') === insight.id) {
      if (scrollRef && !played) {
        setIsExpanded(true);
        scrollRef.scrollIntoView({ behavior: 'auto' });
      }
    }
  }, [scrollRef, searchParams]);

  useEffect(() => {
    if (ref) {
      setScrollRef(ref.current);
    }
  }, []);

  /*
   * Since you can click anywhere, when enters in edition mode we focus
   * the editable context.
   */
  useEffect(() => {
    if (ref.current && (isEditing || isOpen)) {
      ref.current.focus();
    }
  }, [isEditing, isOpen]);

  const handleCancelEdition = () => {
    if (ref.current) {
      ref.current.innerText = bulletText;
    }
    onStopEditing();
  };

  const handleEditionEnded = () => {
    if (onEdit) onEdit(editingText);
    if (ref.current) {
      ref.current.innerText = editingText;
      ref.current.blur();
    }
    onStopEditing();
  };

  const handleBulletClicked = () => {
    if (!isEditing && hasChunks) setIsExpanded(!expanded);
  };

  const handleCheckClicked = (ev: React.MouseEvent<HTMLSpanElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    handleEditionEnded();
  };

  const handleCaretClicked = () => {
    setIsExpanded(expanded => !expanded);
  };

  const handleDeleteClicked = () => {
    onDelete();
  };

  const handleStarClicked = () => {
    manageInsight();
  };

  const handleStartEdition = () => {
    if (editable) {
      onClick();
    }
  };

  const handleChecked = (value: boolean) => {
    setIsChecked(value);
    if (value && recapId) {
      addInsight({ id: insight.id, text: insight.text, eventId: recapId });
    } else {
      removeInsight(insight.id);
    }
  };

  const handleIncludeInEngine = () => {
    if (isRecapExcludedFromInsightsEngine || !permissions.canManageInsightExclusion) {
      return;
    }

    insightExclusionToggled(true);

    includeInsightInEngine(insight.id)
      .then(() => {
        successToast('Insight included successfully');
      })
      .catch(error => {
        const errorMessage = extractError(error);
        errorToast(errorMessage);
      });
  };

  const handleExcludeFromEngine = () => {
    if (isRecapExcludedFromInsightsEngine || !permissions.canManageInsightExclusion) {
      return;
    }

    insightExclusionToggled(false);

    excludeInsightFromEngine(insight.id)
      .then(() => {
        successToast('Insight excluded successfully');
      })
      .catch(error => {
        const errorMessage = extractError(error);
        errorToast(errorMessage);
      });
  };

  const handleCopy = (s: string) => {
    navigator.clipboard.writeText(s);
    successToast('Copied to clipboard');
  };

  const onKeyDown = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    if (ev.key === 'Enter') {
      return handleEditionEnded();
    }

    if (ev.key === 'Escape') {
      return handleCancelEdition();
    }
  };

  /*
   * We use onKeyUp because otherwise the innerText is not yet fully loaded
   */
  const onKeyUp = () => {
    if (ref.current) {
      setEditingText(ref.current.innerText);
    }
  };

  const menuItems: MenuItem[] = useMemo(() => {
    const items: MenuItem[] = [
      {
        label: 'Copy Text',
        key: 'copy_text',
        type: 'item',
        onClick: () => handleCopy(insight.text),
        icon: <CopyOutlined />,
      },
    ];

    if (recapId) {
      items.push({
        label: 'Copy Link',
        key: 'copy_link',
        type: 'item',
        onClick: () =>
          handleCopy(`${window.location.origin}/recap/${recapId}?insightId=${insight.id}`),
        icon: <CopyOutlined />,
      });
    }

    if (openInsight) {
      items.push({
        label: 'Open Insight',
        key: 'open_insight',
        type: 'item',
        onClick: () =>
          window.open(
            `/recap/${recapId}?insightId=${insight.id}`,
            openInsightSamePage ? '_self' : '_blank',
          ),
        icon: <FontAwesomeIcon icon={faExternalLink} />,
      });
    }

    if (showEdit) {
      items.push({
        label: 'Edit',
        key: 'edit',
        type: 'item',
        onClick: () => handleStartEdition(),
        icon: <EditOutlined />,
      });

      if (canExclude) {
        const exclusionMessage = !permissions.canManageInsightExclusion
          ? 'Only the owner of the Recap, the Workspace Owner, and a Workspace Admin can modify the exclusion status'
          : 'Insights from this meeting are currently being excluded from Account Insights and Portfolio Insights. Please first enable them at the meeting level.';
        if (insight.excludedFromInsightsEngine) {
          items.push({
            label: 'Include in Account and Portfolio Insights',
            key: 'include',
            disabled: {
              tooltip: exclusionMessage,
              value: isRecapExcludedFromInsightsEngine || !permissions.canManageInsightExclusion,
            },
            type: 'item',
            onClick: () => handleIncludeInEngine(),
            icon: <BarChartOutlined />,
          });
        } else {
          items.push({
            label: 'Exclude from Account and Portfolio Insights',
            key: 'exclude',
            type: 'item',
            disabled: {
              tooltip: exclusionMessage,
              value: isRecapExcludedFromInsightsEngine || !permissions.canManageInsightExclusion,
            },
            onClick: () => handleExcludeFromEngine(),
            icon: <BarChartOutlined />,
          });
        }
      }
    }

    if (showStarInsight && hasChunks) {
      items.push({
        label: (
          <OptionalTooltipWrapper display={!canFavorite} value={<SubscribeToProPlanLink />}>
            <div style={{ display: 'flex', gap: '10px' }}>
              Favorite {!canFavorite && <UpgradeToProBadge />}
            </div>
          </OptionalTooltipWrapper>
        ),
        key: 'favourite',
        type: 'item',
        onClick: () => {
          if (canFavorite) handleStarClicked();
        },
        icon: <StarOutlined />,
      });
    }

    if (showDelete) {
      items.push({ type: 'divider' });

      items.push({
        label: (
          <MenuLabel disabled={false} style={{ color: 'red' }}>
            Delete
          </MenuLabel>
        ),
        key: 'delete',
        type: 'item',
        onClick: () => handleDeleteClicked(),
        icon: <DeleteOutlined style={{ color: 'red' }} />,
      });
    }

    return items;
  }, [isPublic, editable, insight, isRecapExcludedFromInsightsEngine, canExclude]);

  return (
    <Container
      onMouseEnter={() => setIsContainerHovered(true)}
      onMouseLeave={() => setIsContainerHovered(false)}
    >
      <Header hoverColor={color}>
        {!isPublic && (
          <OptionalTooltipWrapper
            display={!hasChunks || disableCheckbox}
            value={
              !hasChunks
                ? `This insight was manually created or we couldn't find references in the transcription`
                : 'Max insights for AI summary reached. We do this to ensure top quality for team sharing.'
            }
          >
            <Checkbox
              disabled={disableCheckbox}
              onChange={ev => handleChecked(ev.target.checked)}
            />
          </OptionalTooltipWrapper>
        )}

        {hasChunks && (
          <span style={{ alignSelf: 'flex-start', marginTop: '4px' }}>
            {expanded ? (
              <CaretDownOutlined onClick={handleCaretClicked} />
            ) : (
              <CaretRightOutlined onClick={handleCaretClicked} />
            )}
          </span>
        )}

        <BulletEditable
          id={`recap-insight-${insight.id}`}
          contentEditable={isEditing}
          isOpen={expanded}
          ref={ref}
          hoverColor={hoverColor}
          onClick={handleBulletClicked}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          color={color}
          editable={editable}
          editing={isEditing || isOpen}
        />

        {canExclude && !isPublic && (
          <Tooltip
            title={
              isRecapExcludedFromInsightsEngine
                ? `Insights from this meeting are currently being excluded from Account Insights and Portfolio Insights. Please first enable them at the meeting level.`
                : `This insight is ${
                    insight.excludedFromInsightsEngine
                      ? ' being excluded from Account and Portfolio Insights'
                      : 'included in Account and Portfolio Insights'
                  } `
            }
          >
            {insight.excludedFromInsightsEngine ? (
              <InsightExcluded onClick={handleIncludeInEngine} />
            ) : (
              <InsightIncluded onClick={handleExcludeFromEngine} />
            )}
          </Tooltip>
        )}

        {canEdit ? (
          <ActionsContainer style={{ width: '16px', height: '16px' }}>
            {showFloatingBar && !isEditing && (
              <Menu items={menuItems}>
                <MoreOutlined style={{ fontSize: '20px' }} />
              </Menu>
            )}
          </ActionsContainer>
        ) : (
          <></>
        )}

        {isEditing && (
          <FloatingBar style={{ display: showFloatingBar ? 'inline-flex' : 'none' }}>
            <Space style={{ gap: '8px' }}>
              <Button size="small" type="primary" onClick={handleCheckClicked}>
                Done
              </Button>
              <Button onClick={handleCancelEdition} size="small">
                Cancel
              </Button>
            </Space>
          </FloatingBar>
        )}
      </Header>

      {expanded && (
        <MessageList color={color}>
          {messages.map(message => {
            return (
              <Message
                openInsightSamePage={openInsightSamePage}
                highlightColor={smartClipColor}
                key={message.id}
                message={message}
                insight={insight}
                play={play}
                recapId={recapId}
              />
            );
          })}
        </MessageList>
      )}
    </Container>
  );
};
