import React, { useContext, useState } from 'react';
import { differenceInDays } from 'date-fns';
import { Tooltip } from 'antd';
import { Mapping } from '../../../../features/crm/property-mapping/types';
import { propertyMappingContext } from '../../../../features/crm/property-mapping/property-mapping.context';
import { PropertyValue } from './components/PropertyValue';
import { useAccountOverview } from '../../../../features/account/account-overview/account-overview.hook';
import { DealNameProperty } from './components/DealNameProperty';
import { formatDateToDayMonthYear } from '../../../../utils/dateUtils';
import { SwapOutlined } from '@ant-design/icons';
import { colors } from '../../../../theme/colors';

import Styles from './style';
const { Section, SectionTitle2, SectionHeader } = Styles;

const getCrmPropertyValue = (
  mapping: Mapping[],
  propertyId: string,
  accountProperty?: string | number,
  defaultMessage?: string,
  formattingFn: (value: any) => string = value => value,
) => {
  const property = mapping.find(m => m.property.id === propertyId);

  if (!property) {
    return null;
  }

  if (!property.crmProperty) {
    return null;
  }

  if (accountProperty) {
    return formattingFn(accountProperty);
  }

  return defaultMessage || 'No value provided';
};

export const Overview: React.FC = () => {
  const { store } = useContext(propertyMappingContext);
  const { selectedAccount: account, primaryDealChanged } = useAccountOverview();
  const { mapping } = store();

  const [isEditing, setIsEditing] = useState(false);

  const daysEngaged = (date?: Date) => {
    const difference = differenceInDays(new Date(), new Date(date || ''));
    if (isNaN(difference)) {
      return '-';
    } else {
      return `${difference} ${difference > 1 || difference === 0 ? 'days' : 'day'} ago`;
    }
  };

  const resolveLastMeetingLabel = (
    lastMeetingInformation?: null | {
      date: Date;
      users: { id: string; email: string; fullName: string }[];
    },
  ) => {
    if (!lastMeetingInformation || !lastMeetingInformation.users.length) return <span>-</span>;

    const { date, users } = lastMeetingInformation;
    const formatedDate = daysEngaged(new Date(date || ''));
    const usersLabel = users.length > 1 ? 'Multiple' : users[0].email;
    const participantsEmails = (
      <>
        <strong>{'Meeting participants\n'}</strong>
        {users.map(user => user.email).join('\n')}
      </>
    );

    if (users.length > 1) {
      return (
        <span>
          {' '}
          {formatedDate} <Tooltip title={participantsEmails}>{`(${usersLabel})`}</Tooltip>
        </span>
      );
    } else {
      return <span>{`${formatedDate} (${usersLabel})`}</span>;
    }
  };

  const handlePrimaryDealChanged = (primaryDealId: string) => {
    const deal = account.deals.find(deal => deal.id === primaryDealId);
    if (deal) {
      primaryDealChanged(deal);
    }
  };

  return (
    <div style={{ display: 'flex', gap: '10px' }}>
      <Section>
        <SectionTitle2>Deal/Opportunity </SectionTitle2>

        <SectionHeader>
          Name{' '}
          <SwapOutlined
            style={{ color: colors.primary[500] }}
            onClick={() => setIsEditing(!isEditing)}
          />
        </SectionHeader>
        <SectionHeader>Amount</SectionHeader>
        <SectionHeader>Stage</SectionHeader>
        <SectionHeader>Renewal Date</SectionHeader>

        <DealNameProperty
          deals={account.deals}
          primaryDealId={account.primaryDeal?.id || null}
          onPrimaryDealChanged={handlePrimaryDealChanged}
          accountId={account.id}
          isEditing={isEditing}
          handleIsEditing={setIsEditing}
        />
        <PropertyValue
          crmPropertyValue={getCrmPropertyValue(
            mapping,
            'contractValue',
            account.primaryDeal?.contractValue,
            undefined,
            value => `$ ${value}`,
          )}
        />
        <PropertyValue
          crmPropertyValue={getCrmPropertyValue(mapping, 'lifecycleStage', account.lifecycleStage)}
        />
        <PropertyValue
          crmPropertyValue={getCrmPropertyValue(
            mapping,
            'closedDate',
            account.primaryDeal?.closedDate,
            undefined,
            value => formatDateToDayMonthYear(value),
          )}
        />
      </Section>

      {/* <Section style={{ gridTemplateColumns: 'auto auto' }}> */}
      <Section style={{ gridTemplateColumns: 'auto ' }}>
        <SectionTitle2>Meetings</SectionTitle2>

        {/* <SectionHeader>Next Meeting</SectionHeader> */}
        {/* <></> */}

        <SectionHeader>Last Meeting</SectionHeader>
        <PropertyValue crmPropertyValue={resolveLastMeetingLabel(account.lastMeetingDateAll)} />
      </Section>

      <Section style={{ gridTemplateColumns: 'auto' }}>
        <SectionTitle2>Accounts</SectionTitle2>

        <SectionHeader>Segment</SectionHeader>
        <PropertyValue
          crmPropertyValue={getCrmPropertyValue(mapping, 'revenueSegment', account.revenueSegment)}
        />
      </Section>
    </div>
  );
};
