import React, { useEffect, useMemo, useState } from 'react';
import { PastMeetings } from './PastMeetings';
import { Stakeholders } from './Stakeholders';
import Styles from './style';
import TabComponent from '../../../../features/shared/components/TabV2';
import { Card } from '../../../../components/Card';
import { InsightTrends } from './InsightTrends';
import { Topics } from './Topics';
import { AccountMeddicc } from './MEDDICC';
import { useSession } from '../../../../hooks/useSession';

const { Contents } = Styles;
const { Tab, TabItem } = TabComponent;

export const ACCOUNT_DATE_FORMAT = 'MMMM dd, yyyy';

interface Props {
  currentTab?: string;
  onTabSelected: (tab: string) => unknown;
}

export const AccountTabs: React.FC<Props> = ({ currentTab = 'past-meetings', onTabSelected }) => {
  const [selectedTab, setSelectedTab] = useState('past-meetings');
  const { user } = useSession();

  const isUpdateUser = useMemo(() => {
    if (user) {
      return user.email?.endsWith('@update.ai') || user.email === 'jamie@vitally.io';
    }

    return false;
  }, [user]);

  useEffect(() => {
    setSelectedTab(currentTab);
  }, [currentTab]);

  const handleSelectedTab = (selectedTab: string) => {
    setSelectedTab(selectedTab);
    onTabSelected(selectedTab);
  };

  const items = useMemo(() => {
    const partialTabs = [
      {
        key: 'past-meetings',
        label: <TabItem isActive={selectedTab === 'past-meetings'} label="Past Meetings" />,
        children: <PastMeetings />,
      },
      {
        key: 'stakeholders',
        label: <TabItem isActive={selectedTab === 'stakeholders'} label="Stakeholders" />,
        children: <Stakeholders />,
      },
    ];

    if (isUpdateUser) {
      partialTabs.push(
        {
          key: 'insight-trends',
          label: <TabItem isActive={selectedTab === 'insight-trends'} label="Insight Trends" />,
          children: <InsightTrends />,
        },
        {
          key: 'topics',
          label: <TabItem isActive={selectedTab === 'topics'} label="Topics" />,
          children: <Topics />,
        },

        {
          key: 'meddicc',
          label: <TabItem isActive={selectedTab === 'meddicc'} label="MEDDICC" />,
          children: <AccountMeddicc />,
        },
      );
    }

    return partialTabs;
  }, [selectedTab]);

  return (
    <Card>
      <Contents>
        <Tab items={items} activeKey={selectedTab} onChange={handleSelectedTab} />
      </Contents>
    </Card>
  );
};
