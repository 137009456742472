import styled from 'styled-components';

const Buttons = styled.div`
  display: flex;
  gap: 10px;
`;

const EmptyView = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Cool-Gray-400, #566976);
`;

const Title = styled.div`
  color: var(--Gray-950, #282828);
  font-family: 'Cera Pro';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
`;

const LastGeneratedLabel = styled.span`
  display: flex;
  align-items: center;

  padding: 2px 5px;
  color: #0675fc;

  font-family: 'Cera Pro';
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: var(--4px, 4px);

  border: 1px solid #b6e8ff;

  background: #edf9ff;
  font-size: 11px;
  font-weight: 400;
`;

export default { Buttons, EmptyView, Title, LastGeneratedLabel };
