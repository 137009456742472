import { PropsWithChildren, createContext, useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useStore } from 'zustand';
import { AccountOverviewStore, createAccountOverviewStore } from './account-overview.store';
import { coreAPI } from '../../../API/core';

const core = new coreAPI();

interface AccountOverviewContext {
  store: AccountOverviewStore;
  setAccountId: (accountId: string) => unknown;
}

export const accountOverviewContext = createContext<AccountOverviewContext>({
  store: createAccountOverviewStore(),
  setAccountId: () => null,
});

export const AccountOverviewProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [accountId, setAccountId] = useState<string>('');

  const store = useRef(createAccountOverviewStore()).current;
  const dateRange = useStore(store, store => store.dateRange);
  const myMeetingsOnly = useStore(store, store => store.myMeetingsOnly);
  const setSelectedAccount = useStore(store, store => store.setSelectedAccount);
  const fetchStatusChanged = useStore(store, store => store.fetchStatusChange);

  const { isLoading, data } = useQuery({
    queryKey: [accountId, dateRange, myMeetingsOnly],
    queryFn: async () => {
      if (accountId) {
        const account = await core.getAccount(
          accountId,
          dateRange[0],
          dateRange[1],
          myMeetingsOnly,
        );
        return account;
      }
      return null;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchInterval: 30000,
  });

  useEffect(() => {
    if (data) {
      setSelectedAccount(data);
    }
  }, [data]);

  useEffect(() => {
    fetchStatusChanged(isLoading);
  }, [isLoading]);

  return (
    <accountOverviewContext.Provider value={{ store, setAccountId }}>
      {children}
    </accountOverviewContext.Provider>
  );
};
