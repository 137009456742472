import React, { useEffect } from 'react';
import { TabsBar } from '../../../shared/components/Tab/Tabs';
import { Card, VideoContainer } from './styles';
import { transcriptTab } from './tabs';
import { useVideoPlayer } from '../../../video/hooks';
import { coreService } from '../../../../services/core/core-service';
import { useToast } from '../../../../hooks/useToast';
import { extractError } from '../../../../utils/api';
import { Video } from '../Video/Video';
import { useRecapManagement } from '../../../recapv2/recap-management/hook';

export const VideoAndTranscript: React.FC = () => {
  const { permissions, id, playback, cropping } = useRecapManagement();
  const recapId = id;
  const { setIsEditable, setCropCallback, setCropping, setContainerRef } = useVideoPlayer();
  const { success, error } = useToast();

  useEffect(() => {
    // Assuming that the video will only be editable if the user has the permission to manage share links.
    setIsEditable(permissions.canManageShareLinks);
  }, [permissions.canManageShareLinks]);

  useEffect(() => {
    if (playback) {
      setCropping({ start: cropping.start, end: cropping.end });
    }
  }, [playback, cropping]);

  useEffect(() => {
    setCropCallback(args =>
      coreService
        .cropPlaybackCropping(recapId, args)
        .then(() => {
          success('Video saved successfully');
        })
        .catch(apiError => {
          const errorMessage = extractError(apiError);
          error(errorMessage);
        }),
    );
  }, [recapId]);

  return (
    <Card>
      <VideoContainer ref={setContainerRef}>
        <Video />
      </VideoContainer>
      <TabsBar defaultKey="transcript" items={[transcriptTab]}></TabsBar>
    </Card>
  );
};
